import React from "react";
import MaterialTable, { Column, Options } from "material-table";
import { ThemeProvider, createTheme, Switch, Button, FormControlLabel, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem } from '@mui/material';
import { formatDateString } from "../util";
import { hrNotesTagArray, hrNotesTagImpl, HrNoteTagKey, IHrDataMap, IHrNoteRestObject, IOneData, isKeyOf, IStenaUser, Screensize } from "../types/types";
import ThumbUp from '@mui/icons-material/ThumbUpOffAlt';
import './EmployeeTable.css';
import './HREmployeeTable.css';
import { AccessLevel, ActiveView } from "./LoggedIn";
import EditDialog from "./EditDialog";
import SimplePrompt from "./SimplePrompt";


interface IHrEmployeeTableProps {
  accessLevel: AccessLevel;
  context: any;
  screensize: Screensize;
  switchView: (view: ActiveView) => void;
  users: IStenaUser[];
  hrNotes: IHrDataMap;
  updateNote: (comment: IHrNoteRestObject) => void;
}

interface IHrEmployeeTableState {
  userToUpdate: IStenaUser;
  editDialogOpen: boolean;
  showHidden: boolean;
  filteredFields: HrNoteTagKey[];
  hideFiltered: boolean;
  chosenUpns: IChosenUpnByEmployeeIds;
  oneDataForPrompt?: IOneData;
}

interface IChosenUpnByEmployeeIds {
  [employeeId: string]: string;
}

const emptyStenaUser: IStenaUser = {
  employeeId: "",
  activated: false,
  azureAccounts: [],
  birthDate: "",
  cardNumber: "",
  company: "",
  costCenter: "",
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  pinCodeOrigo: "",
  timestamp: "",
  activatedUpn: "",
}

class HREmployeeTable extends React.Component<IHrEmployeeTableProps, IHrEmployeeTableState> {

  constructor(props: IHrEmployeeTableProps) {
    const chosenUpns: IChosenUpnByEmployeeIds = {};
    props.users.forEach(u => {
      if (Array.isArray(u.azureAccounts) && u.azureAccounts.length > 0) {
        chosenUpns[u.employeeId] = u.azureAccounts[0].upn;
      }
    })

    super(props);
    this.state = {
      userToUpdate: props.users[0] || emptyStenaUser,
      editDialogOpen: false,
      showHidden: false,
      filteredFields: [],
      hideFiltered: true,
      chosenUpns
    }
  }

  private showThumbUp = (user: IStenaUser): boolean => {
    if (user.hrApproved) return true;
    const note = this.props.hrNotes[user.employeeId]
    if (note && note.hrApprove) return true;
    return false;
  }

  private filteredUsers = () => {
    return this.props.users.filter(user => {
      var note = this.props.hrNotes[user.employeeId];
      return this.state.hideFiltered ?
        !this.state.filteredFields.some(f => note.tags.includes(f)) :
        this.state.filteredFields.some(f => note.tags.includes(f))
    })
  }

  private setHrPreferredUpn = (upn: string, employeeId: string) => {
    this.setState({ chosenUpns: { ...this.state.chosenUpns, [employeeId]: upn } });
  }

  private oneDataPromptContent = (oneData: IOneData | undefined) => {
    if (!oneData) return "No data to show"
    return () => (
      <>
        {
          Object.keys(oneData)
            //.filter(key => isKeyOf(oneData, key) &&(oneData[key].valueOf() === "boolean" || !!oneData[key]))
            .map(key => <p key={key}>{`${key}: ${isKeyOf(oneData, key) && oneData[key]}`}</p>)
        }
      </>

    )
  }

  private getOneDataPrompt(oneData: IOneData) {
    this.setState({ oneDataForPrompt: oneData });
  }

  private updateNote = (note: IHrNoteRestObject) => {
    note.hrPreferredUpn = this.state.chosenUpns[note.employeeId];
    this.props.updateNote(note);
  }

  private columns: Array<Column<IStenaUser>> = [
    {
      title: "First Name",
      filterPlaceholder: "Filter by first name!",
      field: "firstName",
      render: rowData => {
        const name = rowData?.azureAccounts?.find(a => a.upn === this.state.chosenUpns[rowData.employeeId])?.oneData?.firstName;
        return (
          <div>
            <p>{rowData.firstName}</p>
            {
              name && <p style={{ color: name.toLowerCase().trim() === rowData.firstName.toLowerCase().trim() ? "green" : "red" }}>{name}</p>
            }
          </div>
        )
      }

    },
    {
      title: "Last Name",
      field: "lastName",
      render: rowData => {
        const name = rowData?.azureAccounts?.find(a => a.upn === this.state.chosenUpns[rowData.employeeId])?.oneData?.lastName;
        return (
          <div>
            <p>{rowData.lastName}</p>
            {
              name && <p style={{ color: name.toLowerCase().trim() === rowData.lastName.toLowerCase().trim() ? "green" : "red" }}>{name}</p>
            }
          </div>
        )
      }
    },
    {
      title: "Birthdate",
      field: "birthDate",
      render: rowData => {
        const birthday = rowData?.azureAccounts?.find(a => a.upn === this.state.chosenUpns[rowData.employeeId])?.oneData?.birthDate;
        return (
          <div>
            <p>{rowData.birthDate}</p>
            {
              birthday && <p style={{ color: birthday.trim() === rowData.birthDate.trim() ? "green" : "red" }}>{birthday}</p>
            }
          </div>
        )
      }
    },
    {
      title: "Origo ID",
      field: "employeeId",
      render: rowData => <p>{rowData.employeeId.search(/[\D]/) >= 0 ? "no origo match" : rowData.employeeId}</p>
    },
    {
      title: "UPN",
      render: rowData => {
        const accounts = rowData.azureAccounts;
        if (!Array.isArray(accounts) || accounts.length === 0) return <p>No Azure account found</p>
        if (accounts.length === 1) return <p>{this.state.chosenUpns[rowData.employeeId]}</p>
        return (
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="upnDropdown" />
            <Select
              labelId="upnDropdown"
              value={this.state.chosenUpns[rowData.employeeId]}
              onChange={(e) => this.setHrPreferredUpn(e.target.value as string, rowData.employeeId)}
            >
              {accounts.map(a => <MenuItem value={a.upn} key={a.upn}>{a.upn}</MenuItem>)}
            </Select>
          </FormControl>
        )
      }

    },
    {
      title: "OneData",
      render: rowData => {
        const oneData = rowData?.azureAccounts?.find(a => a.upn === this.state.chosenUpns[rowData.employeeId])?.oneData;
        return <Button onClick={() => { oneData && this.getOneDataPrompt(oneData) }} style={{ color: oneData ? oneData.isActive ? "green" : "red" : "lightgray" }} disabled={!oneData}>Show data</Button>
      }
    },
    {
      title: "Mail",
      field: "email",
      render: rowData => {
        const mails = rowData?.azureAccounts?.find(a => a.upn === this.state.chosenUpns[rowData.employeeId])?.oneData?.mailAddresses
        return (
          <div>
            <p>{rowData.email}</p>
            {
              mails && mails.map((mail, index) => <p style={{ color: mail.trim() === rowData.email.trim() ? "green" : "red" }} key={index}>{mail}</p>)
            }
          </div>
        )
      }
    },
    {
      title: "Phone",
      field: "phoneNumber",
      render: rowData => {
        const numbers = rowData?.azureAccounts?.find(a => a.upn === this.state.chosenUpns[rowData.employeeId])?.oneData?.phoneNumbers;
        return (
          <div>
            <p>{rowData.phoneNumber}</p>
            {
              numbers && numbers.map((number, index) => <p style={{ color: number.trim() === rowData.phoneNumber.trim() ? "green" : "red" }} key={index}>{number}</p>)
            }
          </div>
        )
      }
    },
    {
      title: "Time added",
      defaultSort: "desc",
      field: "timestamp",
      render: rowData => {
        return <p>{formatDateString(rowData.timestamp)}</p>
      }
    },
    {
      title: "Tags",
      render: rowData => {
        const tags = this.props.hrNotes[rowData.employeeId].tags
        return (
          <div>
            {
              tags.length > 0 ? tags.map(n => <p key={n}>{hrNotesTagImpl[n]}</p>) : ""
            }
          </div>)
      }
    },
    {
      title: "Latest comment",
      render: rowData => {
        const { comments } = this.props.hrNotes[rowData.employeeId];
        return comments.length > 0 ? comments[comments.length - 1] : "";
      }
    },
    {
      title: "Approved",
      render: rowData => this.showThumbUp(rowData) && <ThumbUp />
    },
    {
      title: "Action",
      render: rowData => <Button variant="contained" onClick={() => this.setState({ userToUpdate: rowData, editDialogOpen: true })}>Update</Button>
    },
  ];

  private activatedOptions: Options<IStenaUser> = {
    showTextRowsSelected: false,
    emptyRowsWhenPaging: false,
    addRowPosition: "first",
    searchFieldAlignment: "left",
    pageSize: 100,
    pageSizeOptions: [5, 10, 100],
    sorting: true,
    headerStyle: {
      color: '#000000',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontWeight: 700,
      fontSize: "13px",
    },
    rowStyle: {
      height: "100px"
    },
    filterRowStyle: {
      backgroundColor: '#494239',
      color: '#5E5E5E',
    },
  };

  private updateFilter = (e: SelectChangeEvent<HrNoteTagKey[]>) => {
    const newFilter = e.target.value as HrNoteTagKey[];
    this.setState({ filteredFields: newFilter });
  }

  render(): React.ReactNode {

    const { userToUpdate, editDialogOpen, oneDataForPrompt } = this.state;
    return (
      <div className="container">
        <SimplePrompt
          data={{
            title: "OneData",
            description: this.oneDataPromptContent(oneDataForPrompt),
            dismissButtonLabel: "Close",
          }}
          open={oneDataForPrompt !== undefined}
          callbackDismiss={() => this.setState({ oneDataForPrompt: undefined })}
        />
        <EditDialog
          accessLevel={this.props.accessLevel}
          updateUser={() => { }}
          isOpen={editDialogOpen}
          setIsOpen={(value: boolean) => this.setState({ editDialogOpen: value })}
          user={userToUpdate}
          hrNote={this.props.hrNotes[userToUpdate.employeeId]}
          sendSms={() => { }}
          updateNote={this.updateNote}
          context={this.props.context}
          sendCheckPhoneEmail={() => { }}
        />
        <div>
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          <ThemeProvider theme={createTheme()}>
            <div className="headerDiv">
              <h1>Users without pin</h1>
              <div className="switchDiv">
                <h3 style={{ alignSelf: 'center' }}>Show only</h3>
                <div style={{ alignSelf: 'center' }}>
                  <Switch
                    checked={this.state.hideFiltered}
                    onChange={() => { this.setState({ hideFiltered: !this.state.hideFiltered }) }}
                  />
                </div>
                <h3 style={{ alignSelf: 'center' }}>Hide</h3>

                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="filterDropdown" />
                  <Select
                    labelId="filterDropdown"
                    multiple
                    value={this.state.filteredFields}
                    onChange={this.updateFilter}
                  >
                    {hrNotesTagArray.filter(t => t !== "Hidden").map(t => <MenuItem value={t} key={t}>{hrNotesTagImpl[t]}</MenuItem>)}
                  </Select>
                </FormControl>
                <Button onClick={() => this.props.switchView("read")}>Activated</Button>
                {this.props.accessLevel === "hasAdminAccess" && <Button onClick={() => this.props.switchView("admin")}>Unactivated</Button>}
              </div>
            </div>
            <MaterialTable
              title=""
              columns={this.columns}
              data={this.filteredUsers()}
              options={this.activatedOptions}
            />
          </ThemeProvider>
        </div>
      </div>
    )
  }
}

export default HREmployeeTable;