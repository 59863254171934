import { useMsal } from "@azure/msal-react";
import { loginRequest, loginRequestBe } from "./authConfig";


const requestAccessToken = (context: any, be: boolean=false): Promise<string> => {

  function getAccessToken() {
    const request = be ?
    {
      ...loginRequestBe,
      account: context.accounts[0]
    }
    :
    {
      ...loginRequest,
      account: context.accounts[0]
    }

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    return context.instance.acquireTokenSilent(request).then((response: any): Promise<string> => {
      return response.accessToken;
    }).catch(() => {
      return context.instance.acquireTokenPopup(request).then((response: any): Promise<string> => {
        return response.accessToken;
      });
    });
  }

  return getAccessToken();
};

export const requestAccessTokenBE = (context: any) => requestAccessToken(context, true);

export const requestAccessTokenAD = (context: any) => requestAccessToken(context, false);
