import React from "react";
import MaterialTable, { Column, Options } from "material-table";
import { ThemeProvider, createTheme, Select, MenuItem, SelectChangeEvent, InputLabel, FormControl, Checkbox, Switch, Button } from '@mui/material';
import './EmployeeTable.css';
import EditDialog from "./EditDialog";
import { formatDateString } from "../util";
import { AzureInfo, IStenaUser, IActivationResult, IHrNoteRestObject, SmsType, IHrDataMap, hrNotesTagImpl, isKeyOf, IOneData, IAzureUser } from "../types/types";
import SimplePrompt from "./SimplePrompt";
import ThumbUp from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDown from '@mui/icons-material/ThumbDownOffAlt';
import { AccessLevel, ActiveView, UpdateType } from "./LoggedIn";
import { ActivationStatusDisplay } from "./promptContents/ActivationStatusDisplay";


interface IEmployeeTableProps {
    accessLevel: AccessLevel;
    context: any;
    users: IStenaUser[];
    activatedUpns: string[];
    hrNotes: IHrDataMap;
    activationResults: IActivationResult[];
    activationResultsViewOpen: boolean;
    toggleActivationResultsView: (open: boolean) => void;
    updateUser: (user: IStenaUser, updateType: UpdateType) => void;
    activateUserCallback: (stenaUser: IStenaUser, azureInfo: AzureInfo) => void;
    switchView: (view: ActiveView) => void;
    updateNote: (comment: IHrNoteRestObject) => void;
    sendSms: (comment: IHrNoteRestObject, type: SmsType) => void;
    activationQueueCount: number;
    sendCheckPhoneEmail: (comment: IHrNoteRestObject, stid?: string) => void;
}

export type StatusDisplayColor = "red" | "green" | "black" | "blue" | "yellow";
export type StatusDisplay = {
    color: StatusDisplayColor;
    message?: string;
}
export const isStatusDisplayColor = (value: string): value is StatusDisplayColor => value === "green" || value === "red";

// maps employeeId to UPN
export interface ICurrentAdUserForStenaUsers {
    [index: string]: AzureInfo
}


interface IEmployeeTableState {
    rowStates: ICurrentAdUserForStenaUsers
    userToEdit: IStenaUser
    editDialogOpen: boolean;
    activationResultsIndex: number;
    oneDataForPrompt?: IOneData;
    showHidden: boolean;
}

class EmployeeTable extends React.Component<IEmployeeTableProps, IEmployeeTableState> {

    constructor(props: IEmployeeTableProps) {
        super(props);

        const rowStates: ICurrentAdUserForStenaUsers = {}
        props.users.forEach(user => {
            if (user.azureAccounts.length !== 0)
                rowStates[user.employeeId] = new AzureInfo(user.azureAccounts);
        })

        this.state = {
            rowStates,
            userToEdit: props.users[0],
            editDialogOpen: false,
            activationResultsIndex: props.activationResults.length - 1,
            showHidden: false
        };
    }


    componentDidUpdate(prevProps: Readonly<IEmployeeTableProps>, prevState: Readonly<IEmployeeTableState>): void {
        if (prevProps.users !== this.props.users) {
            const rowStatesToAdd: ICurrentAdUserForStenaUsers = {};
            this.props.users.forEach(user => {
                if (user.azureAccounts.length === 0) return;
                if (!prevState.rowStates[user.employeeId] || prevProps.users.find(u => u.employeeId === user.employeeId)?.azureAccounts !== user.azureAccounts) {
                    rowStatesToAdd[user.employeeId] = new AzureInfo(user.azureAccounts)
                }
            });
            if (Object.keys(rowStatesToAdd).length > 0) {
                this.setState({
                    rowStates: {
                        ...prevState.rowStates,
                        ...rowStatesToAdd
                    }
                });
            }
        }

    }

    private sendCheckPhoneEmail = (note: IHrNoteRestObject) => {
        var azureInfo = this.state.rowStates[note.employeeId];
        var stid = azureInfo?._chosenAzureUser?.oneData?.stid;
        this.props.sendCheckPhoneEmail(note, stid);
    }

    private oneDataPromptContent = (oneData: IOneData | undefined) => {
        if (!oneData) return "No data to show"
        return () => (
            <>
                {
                    Object.keys(oneData)
                        //.filter(key => isKeyOf(oneData, key) &&(oneData[key].valueOf() === "boolean" || !!oneData[key]))
                        .map(key => <p key={key}>{`${key}: ${isKeyOf(oneData, key) && oneData[key]}`}</p>)
                }
            </>

        )
    }

    private getOneDataPrompt(oneData: IOneData) {
        this.setState({ oneDataForPrompt: oneData });
    }

    private handleDropdownChange = (event: SelectChangeEvent, person: IStenaUser) => {
        const azureInfo = this.state.rowStates[person.employeeId];
        azureInfo.setChosenAzureUser(event.target.value)
        const rowStates = {
            ...this.state.rowStates,
            [person.employeeId]: azureInfo
        }
        this.setState({ rowStates });
    };


    private handleSetPwForUser = (userId: string) => {
        const rowState = this.state.rowStates[userId];
        if (!rowState) return;
        rowState.changePassword = !rowState.changePassword;
        const newRowStates = {
            ...this.state.rowStates,
            [userId]: rowState
        }
        this.setState({
            rowStates: newRowStates
        });
    }

    private handleIgnoreBirthdateCheckbox = (user: IStenaUser) => {
        var azureInfo = this.state.rowStates[user.employeeId];
        if (!azureInfo) return;
        azureInfo._ignoreBirthdate = !azureInfo._ignoreBirthdate;
        this.setState({
            rowStates: {
                ...this.state.rowStates,
                [user.employeeId]: azureInfo
            }
        });
    }

    private getHrApproveSignalColor = (user: IStenaUser): StatusDisplay => {
        const legitPin = user.pinCodeUserInput
        const hrNote = this.props.hrNotes[user.employeeId]
        const oneData = this.state.rowStates[user.employeeId]?.chosenAzureUser?.oneData;

        var hrApproved = hrNote && hrNote.hrApprove;
        var phoneMatch = oneData?.phoneNumbers?.map(pn => this.comparePhonenumbers(pn, user.phoneNumber));

        var phoneFullMatch = phoneMatch?.includes("fullmatch");
        var phonePartialMatch = phoneMatch?.includes("partialmatch");

        if (oneData) {
            const activeEmployment = !oneData.groupEndDate || Date.parse(oneData.groupEndDate) > Date.now();
            if (!activeEmployment) return { color: "red", message: oneData.groupEndDate };
        }

        if (phoneFullMatch || legitPin || hrApproved) return { color: "green" };
        else if (phonePartialMatch) return { color: "yellow" };
        return { color: "red" };
    }

    private getSeablyStaffbaseMatch = (user: IStenaUser): StatusDisplay => {
        if (user.activatedUpn) return { message: "in activation", color: "blue" };
        const azureUser = this.state.rowStates[user.employeeId]?._chosenAzureUser;

        if (!azureUser) return { color: "red" };
        if (azureUser.accountStatus === "Shore") return { message: "SHORE", color: (azureUser.isStaffbase && azureUser.isSeably) ? "green" : "yellow" };
        if ((azureUser.isStaffbase && azureUser.isSeably) && azureUser.accountStatus === "Primary") return { color: "green" };
        if (azureUser.isStaffbase && azureUser.isSeably) return { color: "yellow" };
        if (azureUser.accountStatus === "Primary") return { message: "primary account", color: "blue" };
        if (azureUser.isStaffbase && !azureUser.isSeably) return { message: "only staffbase", color: "blue" };
        if (!azureUser.isStaffbase && azureUser.isSeably) return { message: "only seably", color: "blue" };
        return { color: "red" };
    }


    private renderDot = (input: StatusDisplay) =>
    // red and green "#86C87B" : "#EB2F1F"
    (<div className="statusCell" style={{ backgroundColor: "white"/*"#86C87B50"*/ }} >
        {input.message
            ? (
                <strong style={{ color: input.color, textAlign: "center" }}>{input.message}</strong>
            )
            : (
                <svg height="100" width="100">
                    <circle cx="50" cy="50" r="16" strokeWidth="5" fill={input.color} />
                </svg>
            )
        }
    </div>)


    private tryOutDateParse = (oneData: IOneData) => {
        const activeEmployment = !oneData.groupEndDate || Date.parse(oneData.groupEndDate) > Date.now();
        console.log(activeEmployment);
    }

    private filteredUsers = () => {
        return this.props.users.filter(user => {
            var note = this.props.hrNotes[user.employeeId];
            if (!note)
                return true;
            return this.state.showHidden ? true : !note.tags.includes("Hidden");

        })
    }

    private comparePhonenumbers = (storedPhone: string, suppliedPhone: string): "fullmatch" | "partialmatch" | "nomatch" => {
        if (!suppliedPhone || !storedPhone || suppliedPhone.trim().length < 5) return "nomatch";

        if (storedPhone.trim() === suppliedPhone.trim()) return "fullmatch";
        else if (storedPhone.includes(suppliedPhone.substring(4).trim())) return "partialmatch";
        return "nomatch";
    }

    private getApproriateColorForPhoneMatch = (phoneMatch: "fullmatch" | "partialmatch" | "nomatch") => {
        switch (phoneMatch) {
            case "fullmatch":
                return "green";
            case "partialmatch":
                return "orange";
            case "nomatch":
                return "red";
        }
    }

    private resolveUpnDisplayColor(account: IAzureUser): "red" | "green" | "black" | "orange" {
        let alreadyActivated = this.props.activatedUpns.includes(account.upn.toLowerCase());
        let enabled = account.accountEnabled;
        if (alreadyActivated && !enabled) return "red";
        if (alreadyActivated && enabled) return "green";
        if (!enabled) return "orange";
        return "black";
    }

    private columns: Array<Column<IStenaUser>> = [
        {
            title: "Given Name (Origo Name)",
            field: "firstName",
            render: rowData => (
                <div>
                    <p>{rowData.firstName}</p>
                    <p style={{ color: "grey" }}>{rowData.origoFirstName}</p>
                </div>
            )
        },
        {
            title: "Last name (Origo Name)",
            field: "lastName",
            render: rowData => (
                <div>
                    <p>{rowData.lastName}</p>
                    <p style={{ color: "grey" }}>{rowData.origoLastName}</p>
                </div>
            )
        },
        {
            title: "ID No",
            field: "employeeId",
            render: rowData => <p>{rowData.employeeId.search(/[\D]/) >= 0 ? "no origo match" : rowData.employeeId}</p>
        },
        {
            title: "Card No",
            field: "cardNumber"
        },
        {
            title: "Birthdate",
            field: "birthDate"
        },
        {
            title: "Phone",
            field: "phoneNumber",
            render: rowData => {
                const numbers = this.state.rowStates[rowData.employeeId]?.chosenAzureUser?.oneData?.phoneNumbers;
                return (
                    <div>
                        <p>{rowData.phoneNumber}</p>
                        {
                            numbers && numbers.map((number, index) => <p style={{ color: this.getApproriateColorForPhoneMatch(this.comparePhonenumbers(number, rowData.phoneNumber)) }} key={index}>{number}</p>)
                        }
                    </div>
                )
            }
        },
        {
            title: "Private mail",
            field: "email",
        },
        {
            title: "Time Added",
            defaultSort: "desc",
            field: "timestamp",
            render: rowData => {
                return formatDateString(rowData.timestamp)
            }
        },
        {
            title: "Approved",
            render: rowData => {
                const oneData = this.state.rowStates[rowData.employeeId]?.chosenAzureUser?.oneData
                return <div className="hrColumn" onClick={() => { oneData && this.getOneDataPrompt(oneData) }}>{this.renderDot(this.getHrApproveSignalColor(rowData))}</div>
            }
        },
        {
            title: "Tags",
            render: rowData => {
                if (!this.props.hrNotes[rowData.employeeId]) return "";
                const { tags } = this.props.hrNotes[rowData.employeeId];
                return (tags.length > 0) ? tags.map(n => <p key={n}>{hrNotesTagImpl[n]}</p>) : "";
            }
        },
        {
            title: "Latest comment",
            render: rowData => {
                if (!this.props.hrNotes[rowData.employeeId]) return "";
                const { comments } = this.props.hrNotes[rowData.employeeId];
                return (comments.length > 0) ? comments[comments.length - 1] : "";
            }
        },
        {
            title: "Status", render: rowData => this.renderDot(this.getSeablyStaffbaseMatch(rowData))
        },
        {
            title: "Ignore Birthdate",
            render: rowData => {
                return (<Checkbox
                    checked={this.state.rowStates[rowData.employeeId] && this.state.rowStates[rowData.employeeId]._ignoreBirthdate}
                    onChange={(e) => { this.handleIgnoreBirthdateCheckbox(rowData) }}
                />);
            }
        },
        {
            title: "Add PW",
            render: rowData => {
                return (<Checkbox
                    disabled={this.state.rowStates[rowData.employeeId] === undefined}
                    checked={this.state.rowStates[rowData.employeeId] ? this.state.rowStates[rowData.employeeId].changePassword : false}
                    onChange={(e) => { this.handleSetPwForUser(rowData.employeeId); }}
                />);
            }
        },
        {
            title: "UPN", render: rowData => {
                let azureInfo = this.state.rowStates[rowData.employeeId];

                var azureAccounts = azureInfo && azureInfo._azureAccounts.filter(azureAccount => {
                    return azureInfo._ignoreBirthdate ?
                        true :
                        azureAccount.birthdate === rowData.birthDate;
                }
                );

                if (azureInfo && azureAccounts.length > 0 && azureAccounts.find(aa => aa.id === azureInfo._chosenAzureUser.id) === undefined) {
                    azureInfo.setChosenAzureUser(azureAccounts.find(aa => aa.isSeaLight)?.id || azureAccounts[0].upn);
                }

                return azureAccounts && azureAccounts.length > 0
                    ? <FormControl fullWidth>
                        <InputLabel id="dropdown">UPN</InputLabel>
                        <Select
                            labelId="dropdown"
                            value={azureInfo.chosenAzureUser.upn}
                            label="UPN"
                            onChange={async (e) => await this.handleDropdownChange(e, rowData)}
                        >
                            {azureAccounts
                                .map((user, index) => {
                                    return (
                                        <MenuItem key={index} value={user.upn}>
                                            <div style={{ color: this.resolveUpnDisplayColor(user) }}>
                                                {user.licenses.length > 0 && <img style={{ width: "15px", height: "15px", margin: "0 2px -2px 2px" }} src={process.env.PUBLIC_URL + "/licens.png"} />}
                                                {user.hasExchangeOnlineMailbox && <img style={{ width: "15px", height: "15px", margin: "0 2px -2px 2px" }} src={process.env.PUBLIC_URL + "/mailbox.jpg"} />}
                                                {user.isSeaLight && <img style={{ width: "15px", height: "15px", margin: "0 2px -2px 2px" }} src={process.env.PUBLIC_URL + "/seal.png"} />}
                                                {user.upn}
                                            </div>
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                    : <FormControl fullWidth>
                        <InputLabel id="dropdown">UPN</InputLabel>
                        <Select
                            labelId="dropdown"
                            value="No azure user found"
                            label="UPN"
                            disabled={true}
                        >
                            <MenuItem key="0" value="No azure user found">No azure user found</MenuItem>
                        </Select>
                    </FormControl>
            }
        },
        {
            title: "", field: "actionButtons", render: rowData =>
                <div className="actionButtonsDiv">
                    {
                        rowData.firstName === "" || rowData.lastName === "" || this.state.rowStates[rowData.employeeId]?.chosenAzureUser === undefined || rowData.activatedUpn
                            ? <button style={{ backgroundColor: "#b3b3b340", color: "#b3b3b3", marginBottom: "10px", cursor: "default" }} className="actionButton">Activate</button>
                            : <button style={{ backgroundColor: "#86C87B40", color: "#22521a", marginBottom: "10px" }} onClick={() => this.sendUserForActivation(rowData)} className="actionButton">Activate</button>
                    }
                    <button onClick={() => this.openEditDialog(rowData)} className="actionButton">Edit</button>
                </div>
        },
    ];

    private options: Options<IStenaUser> = {
        showTextRowsSelected: false,
        emptyRowsWhenPaging: false,
        addRowPosition: "first",
        searchFieldAlignment: "left",
        pageSize: 100,
        pageSizeOptions: [5, 10, 100],
        sorting: true,
        headerStyle: {
            color: '#000000',
            fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
            fontWeight: 700,
            fontSize: "13px",
        },
        rowStyle: {
            height: "100px"
        },
        filterRowStyle: {
            backgroundColor: '#494239',
            color: '#5E5E5E',
        },
    };

    private sendUserForActivation = async (activatedUser: IStenaUser) => {
        const azureInfo = this.state.rowStates[activatedUser.employeeId];
        this.props.activateUserCallback(activatedUser, azureInfo);
    }


    private openEditDialog = async (rowData: IStenaUser) => {
        this.setState({ userToEdit: rowData });
        this.setState({ editDialogOpen: true });
    }

    private getOrCreateHrNoteForUser = (user: IStenaUser): IHrNoteRestObject => {
        let note = this.props.hrNotes[user.employeeId];
        if (!note) {
            note = {
                activationIssues: "",
                comments: [],
                delaySmsSent: false,
                employeeId: user.employeeId,
                hrApprove: false,
                regionNotIncludedSmsSent: false,
                status: "Success",
                tags: [],
                hrPreferredUpn: "",
            };
        }
        return note;
    }

    render(): React.ReactNode {
        const userToEdit = this.state.userToEdit;
        const hrNote = this.getOrCreateHrNoteForUser(userToEdit);
        const oneDataForPrompt = this.state.oneDataForPrompt;
        return (
            <div className="container">
                <SimplePrompt
                    data={{
                        title: "OneData",
                        description: this.oneDataPromptContent(oneDataForPrompt),
                        dismissButtonLabel: "Close",
                    }}
                    open={oneDataForPrompt !== undefined}
                    callbackDismiss={() => this.setState({ oneDataForPrompt: undefined })}
                />
                <SimplePrompt
                    open={this.props.activationResults.length > 0 && this.props.activationResultsViewOpen}
                    data={{
                        title: "Activation",
                        description: () => <ActivationStatusDisplay result={this.props.activationResults[this.state.activationResultsIndex]} />,
                        confirmButtonLabel: (this.state.activationResultsIndex === 0) ? "Close" : "<",
                        dismissButtonLabel: (this.state.activationResultsIndex === this.props.activationResults.length - 1) ? "Close" : ">"
                    }}
                    callbackDismiss={() => {
                        if (this.state.activationResultsIndex === this.props.activationResults.length - 1) {
                            this.props.toggleActivationResultsView(false);
                        } else {
                            this.setState({ activationResultsIndex: this.state.activationResultsIndex + 1 })
                        }
                    }}
                    callbackConfirm={(force?: "force") => {
                        if (this.state.activationResultsIndex === 0 || force) {
                            this.props.toggleActivationResultsView(false);
                        } else {
                            this.setState({ activationResultsIndex: this.state.activationResultsIndex - 1 })
                        }
                    }}

                />
                <EditDialog
                    accessLevel={this.props.accessLevel}
                    updateUser={this.props.updateUser}
                    isOpen={this.state.editDialogOpen}
                    setIsOpen={(value: boolean) => this.setState({ editDialogOpen: value })}
                    user={userToEdit}
                    hrNote={hrNote}
                    sendSms={this.props.sendSms}
                    updateNote={this.props.updateNote}
                    context={this.props.context}
                    sendCheckPhoneEmail={this.sendCheckPhoneEmail}
                />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <ThemeProvider theme={createTheme()}>
                    <div className="headerDiv">
                        <h1>New Users</h1>
                        <div className="symbolExplanations">
                            <p>Account has license: </p>
                            <img style={{ width: "15px", height: "15px", margin: "12px 10px 2px 5px" }} src={process.env.PUBLIC_URL + "/licens.png"} />
                            <p>Account has hasExchangeOnlineMailbox: </p>
                            <img style={{ width: "15px", height: "15px", margin: "12px 10px 2px 5px" }} src={process.env.PUBLIC_URL + "/mailbox.jpg"} />
                            <p>Sea-light-account: </p>
                            <img style={{ width: "15px", height: "15px", margin: "12px 10px 2px 5px" }} src={process.env.PUBLIC_URL + "/seal.png"} />
                        </div>
                        <div>
                            <p><strong>In activation queue: {this.props.activationQueueCount}</strong></p>
                        </div>
                        <div className="switchDiv">
                            <p>Show activation info</p>
                            <Switch checked={this.props.activationResultsViewOpen} onChange={() => { this.props.toggleActivationResultsView(true) }} />
                        </div>
                        <div className="switchDiv">
                            <p>Show hidden</p>
                            <Switch checked={this.state.showHidden} onChange={() => { this.setState({ showHidden: !this.state.showHidden }) }} />
                        </div>
                        <div className="switchDiv">
                            <Button onClick={() => { this.props.switchView('read') }}>Activated</Button>
                            <Button onClick={() => { this.props.switchView('hr') }}>Hr View</Button>
                        </div>

                    </div>
                    <MaterialTable
                        title=""
                        columns={this.columns}
                        data={this.filteredUsers()}
                        options={this.options}
                    />
                </ThemeProvider>
            </div>
        )
    }
}


export default EmployeeTable;