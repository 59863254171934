import { AlertColor } from "@mui/material";
import { stringify } from "querystring";
import { resourceLimits } from "worker_threads";
import { AzureInfo, IActivationResult, IActivationServerResponse, IAzureUser, IStenaUser, StatusTextColor } from "../types/types";

export interface IRequestResult<T> {
  success: boolean;
  payload?: T;
  failMessage?: string;
}

export class RequestResult<T> {
  private _success: boolean;
  private _payload: T | undefined;
  private _failMessage: string;

  constructor(resObj: IRequestResult<T>) {
    const { success, payload, failMessage } = resObj;
    this._success = success;
    this._payload = payload;
    this._failMessage = success ? "Call successfull. No failmessage available." : failMessage || "failmessage not provided to resource."
  }

  get success(): boolean { return this._success }

  get payload(): T {
    if (this.success) {
      if (this._payload === undefined) throw new Error(this._failMessage);
      else return this._payload
    } else throw new Error()
  }

  get failMessage(): string {
    return this._failMessage;
  }
}

export function getAdUser(token: string, user: IStenaUser): Promise<RequestResult<IAzureUser[]>> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);

  return fetch(`useractivation/getaduser?firstname=${user.firstName.trim()}&lastname=${user.lastName.trim()}&birthdate${user.birthDate.trim()}`, {
    method: 'GET',
    headers
  })
    .then(res => {
      if (res.status < 300) {
        return res.json()
          .then((obj): IRequestResult<IAzureUser[]> => {
            return { success: true, payload: obj.user };
          });
      } else return {
        success: false,
        failMessage: "request to getAdUser failed with status: " + res.status.toString()
      }
    })
    .catch(catchMitten)
    .then((res) => new RequestResult(res));
}

export function getUserWithUpn(token: string, upn: string): Promise<RequestResult<IAzureUser>> {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  headers.append("Content-Type", "application/json");

  return fetch(`useractivation/getuserwithupn/${upn.trim()}`, {
    method: 'GET',
    headers
  })
    .then(res => {
      if (res.status < 300) {
        return res.json()
          .then((user): IRequestResult<IAzureUser> => {
            return { success: true, payload: user.user };
          });
      } else return {
        success: false,
        failMessage: "request to getuserwithupn failed with status: " + res.status.toString()
      }
    })
    .catch(catchMitten)
    .then((res) => new RequestResult(res));
}

export function checkIfGroupExists(token: string, title: string): Promise<RequestResult<any>> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);

  return fetch(`useractivation/checkifgroupexists/${title.trim()}`, {
    method: 'GET',
    headers
  })
    .then(res => {
      if (res.status < 300) {
        return res.json()
          .then((result): IRequestResult<any> => {
            return { success: true, payload: result.group };
          });
      } else return {
        success: false,
        failMessage: "request to checkifgroupexists failed with status: " + res.status.toString()
      }
    })
    .catch(catchMitten)
    .then((res) => new RequestResult(res));
}

export function getGroupMembers(token: string, groupId: string): Promise<RequestResult<any>> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);

  return fetch(`useractivation/getgroupmembers/${groupId.trim()}`, {
    method: 'GET',
    headers
  })
    .then(res => {
      if (res.status < 300) {
        return res.json()
      } else return Promise.reject({
        success: false,
        failMessage: "request to getgroupmembers failed with status: " + res.status.toString()
      });
    })
    .then((groupMembers): IRequestResult<any> => {
      return { success: true, payload: groupMembers };
    })
    .catch(catchMitten)
    .then((res) => new RequestResult(res));
}

export function getFilteredMembers(token: string, filterName: string): Promise<RequestResult<any>> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);

  return fetch(`useractivation/getfilteredusers/${filterName.trim()}`, {
    method: 'GET',
    headers
  })
    .then(res => {
      if (res.status < 300) {
        return res.json()
          .then((result): IRequestResult<any> => {
            return { success: true, payload: result.users };
          });
      } else return {
        success: false,
        failMessage: "request to getfilteredusers failed with status: " + res.status.toString()
      }
    })
    .catch(catchMitten)
    .then((res) => new RequestResult(res));
}



// export function sendEmail(token: string, userName: string, mail: string, firstName: string, passwordChange: boolean): Promise<RequestResult<boolean>> {
//   const headers = new Headers();
//   headers.append("Content-Type", "application/json");
// 	headers.append("Authorization" ,`Bearer ${token}`);

//   const body = JSON.stringify({
//     firstName: firstName.trim(),
//     userName: userName.trim(),
//     mail: mail.trim(),
//     passwordChange
//   });
//   return fetch(`useractivation/sendemail`, {
//     method: 'PATCH',
//     headers,
//     body
//   })
//   .then(res => {
//     if (res.status < 300) {
//       return { success: true };
//     } else {
//       return {
//         success: false,
//         failMessage: "request to sendemail failed with status: " + res.status.toString()
//       };
//     }
//   })
//   .catch(catchMitten)
//   .then((res) => new RequestResult(res));
// }

export function activateUserNew(token: string, user: IStenaUser, azureInfo: AzureInfo): Promise<RequestResult<IActivationResult>> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${token}`);

  const body = JSON.stringify({
    user,
    chosenUpn: azureInfo.chosenAzureUser.upn,
    changePassword: azureInfo.changePassword
  });

  const messageTypeParsingArray: StatusTextColor[] = ["red", "orange", "black", "red"]

  return fetch('useractivation/activateuser', {
    method: 'PUT',
    headers,
    body
  })
    .then((response: Response) => {
      if (response.status === 200 || response.status === 413 || response.status === 503) {
        return response.json();
      } else {
        return Promise.reject("Unknown activation fail");
      }
    })
    .then((responseBody: IActivationServerResponse) => {
      //console.log(responseBody)
      const result: IActivationResult = {
        activatedUser: responseBody.activatedUser,
        messages: responseBody.issues.map((issue: { message: string; type: number }) => {
          return {
            message: issue.message,
            severity: messageTypeParsingArray[issue.type]
          }
        })
      }
      return {
        payload: result,
        success: true
      }
    })
    .catch(catchMitten)
    .then((res) => new RequestResult(res));
}

function catchMitten(er: any) {
  console.error(er);
  const failMessage = (er) ? (er instanceof Error) ? er.message : er.toString() : "null";
  return { success: false, failMessage };
}