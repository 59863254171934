import React from "react";
import { Button, TextField } from '@mui/material';
import { IRequestResult, origoAdapter, ORIGO_DATA_SUCCESS_OR_FAIL, RequestResult, submitStenaUser, resendActivationEmail, AutoActivationResult } from "../util";
import { IOrigoUser, IDialogData, IStenaUserInput, Screensize } from '../types/types';
import SimplePrompt from "./SimplePrompt";
import './DataSubmitForm.css';
import { AlreadyActivatedPromptContent } from "./promptContents/AlreadyActivatedPromptContent";
import { helpers } from "./helpers/helpers";
import { InputHolder, InputHolderPublicKey } from "./helpers/inputHolder";

interface IDataSubmitFormProps {
  screensize: Screensize;
  enforceOrigo: boolean;
}


const USER_INPUT_KEY_ARRAY = ["phone", "privateMail", "userInputPin", "userInputId", "userCheckPrivacy", "userCheckPolicy", "repeatPhone", "repeatMail"];
const USER_INPUT_KEY_ARRAY_FOR_INDEXING = ["phone", "privateMail", "userInputPin", "userInputId", "userCheckPrivacy", "userCheckPolicy", "repeatPhone", "repeatMail"] as const;
type UserInputKeyType = typeof USER_INPUT_KEY_ARRAY_FOR_INDEXING[number];

type IUserInput = {
  [key in UserInputKeyType]: string;
}
const UserInputKeys = {
  phone: "phone",
  repeatPhone: "repeatPhone",
  privateMail: "privateMail",
  repeatMail: "repeatMail",
  userInputPin: "userInputPin",
  userInputId: "userInputId",
  userCheckPrivacy: "userCheckPrivacy",
  userCheckPolicy: "userCheckPolicy"
}

export interface IOrigoDataUserInput {
  cardNumber: string;
  firstName: string;
  lastName: string;
  birthDate: string;
}

const isKeyOfIUserInputOrOrigaDataUserInput = (val: string): val is UserInputKeyType => USER_INPUT_KEY_ARRAY.includes(val) || ["firstName", "lastName", "cardNumber", "birthDate"].includes(val);


type SubmitStatusOptions = {
  successManualActivation: IDialogData;
  successAutoActivation: IDialogData;
  fail: IDialogData;
  conflict: IDialogData;
  flawed: IDialogData;
  loading: IDialogData;
  pending: undefined;
}

interface IDataSubmitFormState {
  inputHolder: InputHolder;
  userIpAdress: string;
  loading: boolean,
  submitStatus: keyof SubmitStatusOptions;
  submitAttempted: boolean;
  dialogOpen: boolean;
  file: string;
  error: Error | null;
  inputDelay?: NodeJS.Timeout;
}

/**
 * CONSTANTS
 */

const DIALOG_OPTIONS: SubmitStatusOptions = {
  successManualActivation: {
    title: "Thank You!",
    description: "Your answers have been sent for review and you will be recieving further information as soon as we have verified your account."
  },
  successAutoActivation: {
    title: "Thank You!",
    description: "Your account has been activated and login information will be sent to the email address and phone number you provided shortly, given that you have mobile/cell network coverage."
  },
  fail: {
    title: "Error.",
    description: `Failed to connect. Please try again at another time.`
  },
  conflict: {
    title: "Already registered",
    description: () => <AlreadyActivatedPromptContent />,
    dismissButtonLabel: "Close",
    confirmButtonLabel: "Resend login information"
  },
  flawed: {
    title: "Warning.",
    description: "You have not entered av value for PIN. Proceed anyway?",
    dismissButtonLabel: "Back to form"
  },
  loading: {
    title: "LOADING",
    description: "LOADING"
  },
  pending: undefined
}

type ORIGO_DATA_OR_NULL = IOrigoUser | null;
const SECONDS_TO_SUSPEND_ORIGO_CALL = 1;
export const EMPLOYEE_ID_MIN_LENGTH = 8;
export const EMPLOYEE_ID_MAX_LENGTH = 13;


export type dataSubmitStringsKey = keyof typeof dataSubmitStringsLong;
export const dataSubmitStringsLong = {
  wrongIdFormat: "Unvalid format. Field must contain only numbers",
  tooManyChars: `Too many characters. Between ${EMPLOYEE_ID_MIN_LENGTH} and ${EMPLOYEE_ID_MAX_LENGTH} required`,
  notFound: "Could not find supplied Id No",
  wrongPhoneFormat: "Not a valid phonenumber.",
  wrongPrivateMailFormat: "Not a valid mailadress.",
  wrongPinFormat: "Pin can only contain numbers.",
  triesLeft: "Tries left: ",
  outOfTries: "Out of tries. Please enter fields manually.",
  idFieldHelperUserFound: "Id found. Please fill in form.",
  idFieldHelperUserNotFound: "Please enter a valid Id No.",
  unvalidBirthDateFormat: "Unvalid birthdate format",
  birthDateIncomplete: "Birthdate input is invalid",
  canOnlyContainNumbers: "Field can only contain numbers.",
  nameSuspect: "Are you sure this is correct?",
  submit: "Submit",
  loading: "Loading...",
  idFieldLabel: "Id No (8 digits)",
  firstNameFieldLabel: "Given Name",
  lastNameFieldLabel: "Last Name",
  birthDateFieldLabelUserInput: "Birthdate (YYYY-MM-DD)",
  birthDateFieldLabelOrigo: "Birthdate",
  cardNumberFieldLabel: "Card No (4 - 6 digits)",
  phoneFieldLabel: "Private Mobile No (+ccxxxxxx)",
  repeatPhoneFieldLabel: "Repeat phonenumber",
  noMatchField: "Doesn’t match",
  privateMailFieldLabel: "Private Email",
  repeatPrivateMailFieldLabel: "Repeat Email",
  pinFieldLabel: "Pin Code (4 digits - Optional)",
  privacyLabel: "I agree to the privacy agreement.",
  policyLabel: "I agree to the IT-policy.",
  beforeSubmitText: "Please update my account with the above details. Stena ABs IT User Policy and Information Security Policy apply. For more information on how your personal data is processed, please follow this ",
  "": ""
}


const dataSubmitStringsShort = {
  ...dataSubmitStringsLong,
  outOfTries: "Out of tries",
  idFieldHelperUserNotFound: "No id found.",
  idFieldHelperUserFound: "Id found.",
  wrongIdFormat: "Unvalid format.",
  nameSuspect: "Is this correct?"
}

const defaultState = (): IDataSubmitFormState => ({
  inputHolder: new InputHolder(),
  userIpAdress: "",
  loading: false,
  submitStatus: 'pending',
  submitAttempted: false,
  dialogOpen: false,
  file: "",
  error: null,
});

class DataSubmitForm extends React.Component<IDataSubmitFormProps, IDataSubmitFormState> {

  private dataSubmitStrings;

  constructor(props: IDataSubmitFormProps) {
    super(props);
    this.state = defaultState();
    switch (this.props.screensize) {
      case Screensize.LARGE:
        this.dataSubmitStrings = dataSubmitStringsLong;
        break;
      default:
        this.dataSubmitStrings = dataSubmitStringsShort;
    }
  }

  public componentDidMount(): void {
    fetch('https://geolocation-db.com/json/')
      .then(res => res.status < 300 ? res.json() : null)
      .catch(er => {
        return { IPv4: "" }
      })
      .then(data => {
        if (data) {
          this.setState({
            userIpAdress: data.IPv4
          });
        }
      });
  }
  public componentDidUpdate(prevProps: Readonly<IDataSubmitFormProps>, prevState: Readonly<IDataSubmitFormState>, snapshot?: any): void {

    if (this.state.inputHolder.userInputId !== prevState.inputHolder.userInputId) {
      this.setState({
        inputHolder: this.state.inputHolder.setOrigoData()
      })
    }
    if (prevState.inputHolder.pinMatch && !this.state.inputHolder.pinMatch) {
      this.setState({
        inputHolder: this.state.inputHolder.setOrigoData()
      })
    }
    if (prevProps.enforceOrigo !== this.props.enforceOrigo) {
      this.setState({
        inputHolder: new InputHolder()
      })
    }
  }

  private setOrigoData = (origoId: string, pin: string, ip: string): void => {
    origoAdapter(origoId, pin, ip)
      .then((origoData: ORIGO_DATA_SUCCESS_OR_FAIL) => {

        if (typeof origoData === 'number') {
          this.setState({
            inputHolder: this.state.inputHolder.updatePinTriesLeft(origoData),
            loading: false,
          });
        }
        else if (typeof origoData === 'object') {
          this.setState({
            inputHolder: this.state.inputHolder.setOrigoData(origoData),
            loading: false,
          });
        }
        else if (origoData === "notFound") {
          this.setState({
            loading: false,
            inputHolder: this.state.inputHolder.setOrigoData("notFound"),
          });
        }
        else if (origoData === "outOfPinTries") {
          this.setState({
            inputHolder: this.state.inputHolder.updatePinTriesLeft(-1),
            loading: false,
          })
        }
        else if (origoData === "userAlreadyActivated") {
          this.setState({
            inputHolder: this.state.inputHolder.setValue("userInputId", origoId),
            submitStatus: "conflict",
            loading: false
          });
        }
        else {
          throw new Error("Unexpected origoData type");
        }
      })
      .catch(er => {
        this.setState({
          loading: false,
          error: er,
          submitStatus: "fail"
        })
      });
  }

  private handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const id = e.target.id;

    if (!isKeyOfIUserInputOrOrigaDataUserInput(id)) return;
    const key = id as InputHolderPublicKey;
    let value = e.target.value;
    let inputHolder = this.state.inputHolder;
    switch (key) {
      case UserInputKeys.userInputPin:
        if (value.length > 4) {
          return;
        }
        this.cancelTimeOut();
        if (inputHolder.pinTriesLeft < 0) {
          value = "";
        }
        if (this.userFound() && value.length === 4 && helpers.isProperPin(inputHolder.userInputPin) && inputHolder.pinTriesLeft !== -1) {
          this.delaySetOrigoData(SECONDS_TO_SUSPEND_ORIGO_CALL / 2, inputHolder.userInputId, value, this.state.userIpAdress);
        }
        break;
      case UserInputKeys.userInputId:
        this.cancelTimeOut();
        if (value.length >= EMPLOYEE_ID_MIN_LENGTH && value.length <= EMPLOYEE_ID_MAX_LENGTH && !inputHolder.inputIdMatchesOrigoData) {
          this.delaySetOrigoData(SECONDS_TO_SUSPEND_ORIGO_CALL, value, inputHolder.userInputPin, this.state.userIpAdress);
        }
        break;
    }

    this.setState((prevState) => {
      const newState: IDataSubmitFormState = { ...prevState };
      newState.inputHolder = prevState.inputHolder.setValue(key, value);
      return newState;
    });
  }

  private delaySetOrigoData = (seconds: number, origoId: string, pin: string, userIp: string) => {
    const timer = setTimeout(() => {
      this.setOrigoData(origoId, pin, userIp);
      this.setState({
        loading: true
      })
    }, seconds * 1000);
    this.setState({
      inputDelay: timer
    });
  }

  private userFound(state: IDataSubmitFormState = this.state): boolean {
    return !this.props.enforceOrigo || (state.inputHolder.origoUserFound);
  }

  private cancelTimeOut = () => {
    clearTimeout(this.state.inputDelay);
    this.setState({
      inputDelay: undefined
    });
  }

  private formIncomplete = (): boolean => {
    return this.state.inputHolder.inputIncomplete("loose");
  }

  private sendNewEmailToOldUser = (userInputId: string) => {
    resendActivationEmail(userInputId)
      .then(result => {
        this.setState({ submitStatus: result.success ? "pending" : "fail" })
      })
  }

  private submitAnswers = (forceWithoutPin?: "force"): void => {
    let inputHolder = this.state.inputHolder;

    var phone = helpers.proccessPhoneNumber(inputHolder.phone);

    inputHolder.formatPhoneInput();

    this.setState({
      loading: true
    })

    // check if names have unusual form, and warn user if so on first submit-attempt
    if (!this.state.submitAttempted && inputHolder.namesHaveUnusualForm) {
      setTimeout(() => {
        this.setState({
          submitAttempted: true,
          loading: false
        });
      }, 300);
      return;
    }

    // check if phone and mail match
    if (!inputHolder.phoneAndEmailReadyForSubmit) {

      inputHolder = inputHolder.setValue("repeatMail", inputHolder.hasProperMailAddress ? this.state.inputHolder.repeatMail : "");
      inputHolder = inputHolder.setValue("repeatPhone", inputHolder.hasProperPhoneNumber ? this.state.inputHolder.repeatPhone : "");

      this.setState({
        submitAttempted: true,
        loading: false,
        inputHolder
      });
      return;
    }

    // check if cardnumber or birthdate are incomplete or invalid
    if (inputHolder.inputIncomplete("tight")) {
      this.setState({
        submitAttempted: true,
        loading: false
      });
      return;
    }

    // check if pin is present and correct
    if (this.props.enforceOrigo && !inputHolder.pinPresentAndCorrect && !forceWithoutPin) {
      this.setState({
        submitStatus: "flawed",
        submitAttempted: true,
        loading: false
      });
      return;
    }

    var userInputPin = inputHolder.userInputPin.trim().length < 4 ? "" : inputHolder.userInputPin.trim();
    const completeForm: IStenaUserInput =
    {
      employeeId: inputHolder.userInputId.trim(),
      cardNumber: inputHolder.cardNumber.trim(),
      firstName: inputHolder.firstName.trim(),
      lastName: inputHolder.lastName.trim(),
      birthDate: inputHolder.birthDate.trim(),
      pinCodeUserInput: userInputPin.trim(),
      phoneNumber: phone.trim(),
      email: inputHolder.privateMail.trim(),
      activated: false
    };


    submitStenaUser(completeForm)
      .then((result: RequestResult<AutoActivationResult>) => {
        if (result.success) {
          this.setState({
            submitStatus: result.payload === "SET_FOR_MANUAL_ACTIVATION" ? "successManualActivation" : "successAutoActivation",
            loading: false
          });
          return result
          /*  if (fileTransferObject) return uploadCardImage(fileTransferObject)
          else return { success: false, payload: "Failed to proccess provided file."} */
        } else {
          if (result.failMessage.trim() === "409") {
            this.setState({
              submitStatus: "conflict",
              error: { name: "Request fail", message: result.failMessage },
              loading: false
            });
          } else {
            this.setState({
              submitStatus: "fail",
              error: { name: "Request fail", message: result.failMessage },
              loading: false
            });
          }
          return result;
        }
      })
      .then((result: IRequestResult<string>) => {
        console.log("Submited")
      })
      .catch(er => {
        this.setState({
          error: er,
          loading: false
        });
      });


  }

  private resetPage = () => {
    const { submitStatus, error, userIpAdress } = this.state;
    switch (submitStatus) {
      case 'flawed':
        this.setState({
          inputHolder: this.state.inputHolder.setValue("userInputPin", ""),
          submitStatus: "pending"
        });
        return;
      case 'fail':
        this.setState({
          ...defaultState(),
          userIpAdress,
          error
        });
        return;
      default:
        this.setState({
          ...defaultState(),
          userIpAdress
        });
    }
  }


  public render(): React.ReactElement<IDataSubmitFormProps> {
    const { loading, submitStatus, submitAttempted, inputHolder } = this.state;
    const userInputIdError = this.dataSubmitStrings[inputHolder.userInputIdError]
    const outOfPinTries = inputHolder.pinTriesLeft === -1;
    const correctPincodeFormat = inputHolder.hasProperPin
    const pinInputError = !correctPincodeFormat || outOfPinTries;
    const firstNameInputError = submitAttempted && helpers.containsNonWordChars(inputHolder.firstName.trim());
    const lastNameInputError = submitAttempted && helpers.containsNonWordChars(inputHolder.lastName.trim());
    const privateMailInputError = submitAttempted && !inputHolder.hasProperMailAddress;
    const cardNumberInputError = submitAttempted && helpers.containsNonNumbers(inputHolder.cardNumber);
    const birthDateInputError = (submitAttempted && !inputHolder.hasCompleteBirthdate) || !inputHolder.hasIncompleteButProperBirthdate
    const dialogCallback = submitStatus === 'flawed' ? () => { this.submitAnswers("force"); } : submitStatus === 'conflict' ? () => { this.sendNewEmailToOldUser(inputHolder.userInputId); } : undefined;
    const showPrompt = loading ? "loading" : submitStatus;
    const mailMatch = inputHolder.mailMatch(submitAttempted ? "complete" : "partial");
    const phoneMatch = inputHolder.phoneMatch(submitAttempted ? "complete" : "partial");

    return (
      <div className='SubmitFormContainer'>
        <SimplePrompt open={showPrompt !== "pending"} data={DIALOG_OPTIONS[showPrompt]} callbackConfirm={dialogCallback} callbackDismiss={this.resetPage} />
        <div>
          <div className="text-inputs">
            <form className="textFieldDiv" autoComplete="off" >
              <TextField
                disabled={loading}
                error={userInputIdError.length > 0}
                helperText={userInputIdError || (this.userFound() ? this.dataSubmitStrings.idFieldHelperUserFound : this.dataSubmitStrings.idFieldHelperUserNotFound)}
                fullWidth
                type="tel"
                autoComplete={this.props.screensize === 2 ? "new-password" : ""}
                size='small'
                variant="outlined"
                label={loading ? this.dataSubmitStrings.loading : this.dataSubmitStrings.idFieldLabel}
                // inputProps={origoData ? { style: { color: "rgb(157,158,160)"  } } : {}} SKA TEXTEN BLI GRÅ NÄR ANVÄNDAREN SKRIVIT IN GILTIGT NUMMER?
                placeholder="Enter your Card Id / Origo Id"
                id={UserInputKeys.userInputId}
                onChange={this.handleInput}
                style={{ display: this.props.enforceOrigo ? "flex" : "none" }}
                value={inputHolder.userInputId}
              />
              <TextField style={{ display: this.props.enforceOrigo ? "flex" : "none" }} disabled={!this.userFound() && outOfPinTries} type="tel" autoComplete={this.props.screensize < Screensize.LARGE ? "new-password" : ""}/* style={this.userFound ? {} :  { marginBottom: "1.5rem" } } */ size='small' label={loading ? this.dataSubmitStrings.loading : this.dataSubmitStrings.pinFieldLabel} error={pinInputError} helperText={pinInputError && !correctPincodeFormat ? this.dataSubmitStrings.wrongPinFormat : inputHolder.userInputPinHelperText} id={UserInputKeys.userInputPin} onChange={this.handleInput} fullWidth variant="outlined" value={inputHolder.userInputPin} />
            </form>
            <form className="textFieldDiv" autoComplete="off" >
              <TextField disabled={!this.userFound()} size='small' autoComplete={this.props.screensize === Screensize.LARGE ? "new-password" : ""} label={this.dataSubmitStrings.firstNameFieldLabel} error={firstNameInputError} helperText={firstNameInputError && this.dataSubmitStrings.nameSuspect} id="firstName" onChange={this.handleInput} style={{}} fullWidth variant="outlined" value={inputHolder.firstName} />
              <TextField disabled={!this.userFound()} size='small' autoComplete={this.props.screensize === Screensize.LARGE ? "new-password" : ""} label={this.dataSubmitStrings.lastNameFieldLabel} error={lastNameInputError} helperText={lastNameInputError && this.dataSubmitStrings.nameSuspect} id="lastName" onChange={this.handleInput} style={{}} fullWidth variant="outlined" value={inputHolder.lastName} />
            </form>
            <form className="textFieldDiv" autoComplete="off" >
              <TextField style={{ display: this.props.enforceOrigo ? "flex" : "none" }} disabled={!this.userFound()} size='small' type="tel" autoComplete={this.props.screensize === Screensize.LARGE ? "new-password" : ""} label={this.dataSubmitStrings.cardNumberFieldLabel} error={cardNumberInputError} helperText={cardNumberInputError && this.dataSubmitStrings.canOnlyContainNumbers} id="cardNumber" onChange={this.handleInput} fullWidth variant="outlined" value={inputHolder.cardNumber} />
              <TextField style={{ flexBasis: this.props.enforceOrigo ? "49.5%" : "100%" }} disabled={!this.userFound()} size='small' type="tel" autoComplete={this.props.screensize === Screensize.LARGE ? "new-password" : ""} label={this.dataSubmitStrings.birthDateFieldLabelUserInput} id="birthDate" error={birthDateInputError} helperText={birthDateInputError && this.dataSubmitStrings.birthDateIncomplete} onChange={this.handleInput} fullWidth variant="outlined" value={inputHolder.birthDate} />
            </form>
            {this.props.screensize === Screensize.LARGE ? (
              <>
                <form className="textFieldDiv" autoComplete="off" >
                  <TextField disabled={!this.userFound()} size='small' type="tel" autoComplete={"new-password"} label={this.dataSubmitStrings.phoneFieldLabel} error={submitAttempted && !inputHolder.hasProperPhoneNumber} helperText={submitAttempted && !inputHolder.hasProperPhoneNumber && this.dataSubmitStrings.wrongPhoneFormat} id={UserInputKeys.phone} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.phone} />
                  <TextField disabled={!this.userFound()} size='small' type="email" autoComplete={"new-password"} autoCorrect="false" label={this.dataSubmitStrings.privateMailFieldLabel} error={privateMailInputError} helperText={privateMailInputError && this.dataSubmitStrings.wrongPrivateMailFormat} id={UserInputKeys.privateMail} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.privateMail} />
                </form>
                <form className="textFieldDiv" autoComplete="off" >
                  <TextField disabled={!this.userFound()} size='small' type="tel" autoComplete={this.props.screensize === Screensize.LARGE ? "new-password" : ""} label={this.dataSubmitStrings.repeatPhoneFieldLabel} error={!phoneMatch} helperText={!phoneMatch && this.dataSubmitStrings.noMatchField} id={UserInputKeys.repeatPhone} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.repeatPhone} />
                  <TextField disabled={!this.userFound()} size='small' type="email" autoComplete={this.props.screensize === Screensize.LARGE ? "new-password" : ""} autoCorrect="false" label={this.dataSubmitStrings.repeatPrivateMailFieldLabel} error={!mailMatch} helperText={!mailMatch && this.dataSubmitStrings.noMatchField} id={UserInputKeys.repeatMail} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.repeatMail} />
                </form>
              </>) : (
              <>
                <form className="textFieldDiv" autoComplete="off" >
                  <TextField disabled={!this.userFound()} size='small' type="tel" autoComplete={""} label={this.dataSubmitStrings.phoneFieldLabel} error={!inputHolder.hasProperPhoneNumber} helperText={!inputHolder.hasProperPhoneNumber && this.dataSubmitStrings.wrongPhoneFormat} id={UserInputKeys.phone} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.phone} />
                  <TextField disabled={!this.userFound()} size='small' type="tel" autoComplete={""} label={this.dataSubmitStrings.repeatPhoneFieldLabel} error={!phoneMatch} helperText={!phoneMatch && this.dataSubmitStrings.noMatchField} id={UserInputKeys.repeatPhone} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.repeatPhone} />
                </form>
                <form className="textFieldDiv" autoComplete="off" >
                  <TextField disabled={!this.userFound()} size='small' type="email" autoComplete={""} autoCorrect="false" label={this.dataSubmitStrings.privateMailFieldLabel} error={privateMailInputError} helperText={privateMailInputError && this.dataSubmitStrings.wrongPrivateMailFormat} id={UserInputKeys.privateMail} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.privateMail} />
                  <TextField disabled={!this.userFound()} size='small' type="email" autoComplete={""} autoCorrect="false" label={this.dataSubmitStrings.repeatPrivateMailFieldLabel} error={!mailMatch} helperText={!mailMatch && this.dataSubmitStrings.noMatchField} id={UserInputKeys.repeatMail} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.repeatMail} />
                </form>
              </>
            )}
            <form className="textFieldDiv">
              {/* Checkboxes disabled, plain text used instead
               <FormGroup className="bottomFormGroup">
                <FormControlLabel control={<Checkbox id={UserInputKeys.userCheckPrivacy} disabled={!this.userFound} checked={userCheckPrivacy !== ""} onChange={this.handleInput} />} className="checkBoxField" label={this.dataSubmitStrings.privacyLabel} />
                <FormControlLabel control={<Checkbox id={UserInputKeys.userCheckPolicy} disabled={!this.userFound} checked={userCheckPolicy !== ""} onChange={this.handleInput} />} className="checkBoxField" label={this.dataSubmitStrings.policyLabel} />
              </FormGroup> */}
              <div className="bottomFormGroup plainText">
                <p>{this.dataSubmitStrings.beforeSubmitText}<a href="https://life.stenaline.com/legal/privacypolicy"> link.</a></p>
              </div>
            </form>
          </div>
          {/*FOR LATER IMPLEMENTATION
                  <Button size="large" variant="outlined" component="label" >
                    Upload image
                    <input type='file' id="file" accept="image/*" onChange={this.uploadImage} hidden />
            </Button>*/}
          <Button disabled={this.formIncomplete() || loading} fullWidth size="medium" onClick={() => { this.submitAnswers(); }} variant="outlined">{this.dataSubmitStrings.submit}</Button>
        </div>
      </div>
    );
  }
}

export default DataSubmitForm;