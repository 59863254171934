import { IOrigoUser, isIOrigoUser } from "../../types/types";
import { dataSubmitStringsKey } from "../DataSubmitForm";
import { helpers } from "./helpers";

type InputHolderPrivateKeys =   'setValue' | 'setOrigoData'| '_userInputIdError' | 'userInputIdError' | 'setError' | 'phoneMatch' | 'hasProperPhoneNumber' |
                                'hasProperMailAddress' | 'mailMatch' | 'hasProperPin' | 'hasIncompleteButProperBirthdate' | 'hasCompleteBirthdate' | '_origoData' |
                                'inputIdMatchesOrigoData' | '_pinTriesLeft' | 'userInputPinHelperText' | 'updatePinTriesLeft' | 'pinMatch' | 'pinTriesLeft' |
                                'origoUserFound' | 'inputIncomplete' | 'phoneAndEmailReadyForSubmit' | 'pinPresentAndCorrect' | 'namesHaveUnusualForm' |'formatPhoneInput'; 
export type InputHolderPublicKey = Exclude<keyof InputHolder, InputHolderPrivateKeys>;

export class InputHolder {
    cardNumber: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    phone: string;
    repeatPhone: string;
    privateMail: string;
    repeatMail: string;
    userInputPin: string;
    userInputId: string;
    _userInputIdError: dataSubmitStringsKey;
    _origoData?: IOrigoUser | "pending";
    _pinTriesLeft: number;

    constructor(other: InputHolder | undefined = undefined) {
        this.phone = other?.phone ?? "";
        this.repeatPhone = other?.repeatPhone ?? "";
        this.privateMail = other?.privateMail ?? "";
        this.repeatMail = other?.repeatMail ?? "";
        this.userInputPin = other?.userInputPin ?? "";
        this.userInputId = other?.userInputId ?? "";
        this.cardNumber = other?.cardNumber ?? "";
        this.firstName = other?.firstName ?? "";
        this.lastName = other?.lastName ?? "";
        this.birthDate = other?.birthDate ?? "";
        this._userInputIdError = other?._userInputIdError ?? "";
        this._origoData = other?._origoData;
        this._pinTriesLeft = other?._pinTriesLeft ?? 0;
        this.setValue.bind(this);
    }

    setValue(key: InputHolderPublicKey, value: string): InputHolder {
        if (key === "userInputId"){
            this._userInputIdError = helpers.determineUserInputIdError(value, this._userInputIdError);
        }
        if (key === "birthDate") {
            value = helpers.appendBirthDateDemarkers(value, this.birthDate);
        }
        this[key] = value;
        return new InputHolder(this);
    }

    setOrigoData(origoData?: IOrigoUser | string): InputHolder {
        if (typeof origoData === "string" && origoData === "notFound") {
            this._userInputIdError = origoData;
            this._origoData = undefined;
        } else if (isIOrigoUser(origoData)) {
            const { cardNumber, firstName, lastName, birthDate } = origoData;
            this.cardNumber = cardNumber;
            this.firstName = firstName;
            this.lastName = lastName;
            this.birthDate = birthDate;
            this._origoData = origoData;
        } else {
            return new InputHolder();
        }
        return new InputHolder(this);
    }

    updatePinTriesLeft(pinTriesLeft: number): InputHolder {
        this.userInputPin = "";
        this._pinTriesLeft = pinTriesLeft;
        this._origoData = "pending";
        return new InputHolder(this);
    }

    setError(error: dataSubmitStringsKey){
        this._userInputIdError = error;
    }

    get origoUserFound(): boolean {
        return this._origoData !== undefined;
    }

    get pinMatch(): boolean {
        return isIOrigoUser(this._origoData) ? this.userInputPin.trim() === this._origoData.pin?.trim() : false;
    }

    get pinTriesLeft(): number {
        return this._pinTriesLeft;
    }

    get userInputIdError(): dataSubmitStringsKey{
        return this._userInputIdError;
    }

    phoneMatch(extent: "complete" | "partial"): boolean {
        if (extent === "partial")
            return this.repeatPhone.trim() === this.phone.trim().substring(0,this.repeatPhone.trim().length) && this.repeatPhone.trim().length <= this.phone.trim().length;
        else
            return helpers.isProperPhoneNumber(this.phone) && helpers.proccessPhoneNumber(this.repeatPhone) === helpers.proccessPhoneNumber(this.phone);
    }


    get hasProperPhoneNumber(): boolean {
        return helpers.isProperPhoneNumber(this.phone);
    }

    get hasProperMailAddress(): boolean {
        return helpers.isProperMailAdress(this.privateMail);
    }

    mailMatch(extent: "complete" | "partial"): boolean {
        return this.repeatMail.trim() === this.privateMail.trim().substring(0,this.repeatMail.trim().length) && ((extent === "complete" && this.repeatMail.trim().length === this.privateMail.trim().length) || (extent === "partial" && this.repeatMail.trim().length <= this.privateMail.trim().length));
    }


    get hasProperPin(): boolean {
        return helpers.isProperPin(this.userInputPin);
    }

    get hasIncompleteButProperBirthdate(): boolean {
        return helpers.isProperBirthDate(this.birthDate, "building");
    }

    get hasCompleteBirthdate(): boolean {
        return helpers.isProperBirthDate(this.birthDate, "complete");
    }

    get inputIdMatchesOrigoData(): boolean {
        return this._userInputIdError !== "" && isIOrigoUser(this._origoData) && this.userInputId === this._origoData?.employeeId;
    }

    get userInputPinHelperText(): string {
        const triesLeft = this._pinTriesLeft;
        return triesLeft > 0 ? "Tries left: "+this._pinTriesLeft : triesLeft === 0 ? "Insert pin code" : "No tries left";
    }

    get phoneAndEmailReadyForSubmit(): boolean {
        return this.hasProperPhoneNumber && this.hasProperMailAddress && this.phoneMatch("complete")  && this.mailMatch("complete");
    }

    get pinPresentAndCorrect(): boolean {
        return this.userInputPin !== "" && this.pinMatch;
    }

    get namesHaveUnusualForm(): boolean {
        return helpers.containsNonWordChars(this.firstName.trim()+this.lastName.trim())
    }

    formatPhoneInput(): void {
        this.phone = helpers.proccessPhoneNumber(this.phone);
    }

    inputIncomplete(strictness: "tight" | "loose"): boolean {
        const { firstName, cardNumber, lastName, birthDate } = this;
        const anyFieldNotFilledIn = firstName === "" || lastName === "" || birthDate === "";
        const cardNumberContainsNonNumbers = cardNumber === "" ? false : helpers.containsNonNumbers(cardNumber);
        const invalidBirthDateFormat = !helpers.isProperBirthDate(birthDate, "complete")
        const formIsIncomplete = strictness === "loose" ? anyFieldNotFilledIn : anyFieldNotFilledIn || cardNumberContainsNonNumbers || invalidBirthDateFormat;

        return formIsIncomplete;
    }
}