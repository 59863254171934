import React from "react";
import { Screensize } from "../types/types";
import DataSubmitForm from "./DataSubmitForm";
import './LoggedOut.css';
import { MicrosoftButton } from "./MicrosoftButton";
import Button from "@mui/material/Button";

interface ILoggedOutProps {
  screensize: Screensize;
}

interface ILoggedOutState {
  enforceOrigo: boolean;
}


const registerWithStaffCardDataWelcomeText = "Please type in your staff card ID number and pin code to auto-complete the form. New employees who haven't been given a staff card yet may register without staff card ID by pressing the button in the bottom right. In case you don’t remember your pin code, please fill in the below fields manually. In order to identify you, connect with you and provide you with login information securely, Stena Line requires your email address and mobile number to complete your account. Once Stena Line has activated your account you will receive a text and an email message with login information.";
const registerNewEmployeeWithoutStaffCardWelcomeText = "Please type in your name and birthdate in the form bellow. These will be used to identify the account that has been set up for you. In order to identify you, connect with you and provide you with login information securely, Stena Line requires your email address and mobile number to complete your account. Once Stena Line has activated your account you will receive a text and an email message with login information.";

class LoggedOut extends React.Component<ILoggedOutProps, ILoggedOutState> {

  constructor(props: ILoggedOutProps) {
    super(props);

    this.state = {
      enforceOrigo: true
    };
  }

  public render(): React.ReactElement<ILoggedOutProps> {
    const welcomeText = this.state.enforceOrigo ? registerWithStaffCardDataWelcomeText : registerNewEmployeeWithoutStaffCardWelcomeText;
    return (
      <div className='LoggedOut'>
        <div className='box'>
          <img className="stenaLogo headerItem" src={process.env.PUBLIC_URL+"/stena_big_header.jpeg"/* "https://www.stenaline.se/content/experience-fragments/stenaline/se/sv/site/header/master/_jcr_content/root/container/image_1099437675.coreimg.jpeg/1662452886384/stenaline-digital-rgb-positiv.jpeg" */} />
          <div className="formDiv">
            <p className="loginText headerItem" >{welcomeText}</p>
            <DataSubmitForm screensize={this.props.screensize} enforceOrigo={this.state.enforceOrigo} />
          </div>
        </div>
        <div className="microsoftButtonDiv">
          <MicrosoftButton signIn={true} />
        </div>
        <div className="registerWithoutEmployeeIdButton">

        <Button
          className="registerWithoutEmployeeIdButton"
          onClick={() => this.setState({ enforceOrigo: !this.state.enforceOrigo })}
          size="medium"
          variant={this.state.enforceOrigo ? "outlined" : "contained"}
          >
          {this.state.enforceOrigo ? "Register without Id No." : "Register with employee id"}
        </Button>
          </div>
      </div>
    );
  }
}

export default LoggedOut;


//F1 F3 E1 E3 P1 P2