import { resourceLimits } from 'worker_threads';
import {IOrigoUser } from '../types/types';
import { getBasicOrigoUserByIdAndPin } from './requests';

type FAILMESSAGE = "notFound" | "outOfPinTries" | "userAlreadyActivated" | 1 | 2 | 3;

export const isOrigoData = (object: any): object is IOrigoUser => 'origoId' in object;

export type ORIGO_DATA_SUCCESS_OR_FAIL = Readonly<IOrigoUser> | FAILMESSAGE;

export async function origoAdapter(employeeId: string, pin: string, ip: string): Promise<ORIGO_DATA_SUCCESS_OR_FAIL> {
    let result: ORIGO_DATA_SUCCESS_OR_FAIL;
    /* const fakeTime = () => {
        return new Promise<void>((res)=>{
          setTimeout(()=>{
            res();
          }, 3000)
        })
      }
    await fakeTime(); */
    let response = await getBasicOrigoUserByIdAndPin(employeeId, pin, ip)
    try {

        if (response.success){
            const foundUser = response.payload;
            foundUser.pin = pin;
            result = {
                employeeId: foundUser.employeeId || "",
                firstName: foundUser.firstName || "",
                lastName: foundUser.lastName || "",
                birthDate: foundUser.birthDate || "",
                cardNumber: foundUser.cardNumber || "",
                pin: pin
            }
        }
        else {
            if (response.failMessage === "notFound") result = "notFound"
            else if (response.failMessage === "outOfPinTries") result = "outOfPinTries"
            else if (response.failMessage === "userAlreadyActivated") result = "userAlreadyActivated"
            else if (response.failMessage.match(/^[123]$/) !== null) result = Number(response.failMessage) as FAILMESSAGE;
            else throw new Error(`Error fetching: ${response.failMessage}`);
        }
        return result;
    } catch(er) {
        throw er;
    }
}

