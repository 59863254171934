import React, { Component } from "react";
import './Header.css';
import { MicrosoftButton } from "./MicrosoftButton";

class Header extends Component {
  render() {
      return (
          <div className="outerDiv">
              <img className="logo" src={process.env.PUBLIC_URL+"/logotype_flag.png"/* "https://stena.com/app/themes/stena-theme/resources/assets/images/logotype_flag.png" */} />
              <div className="loggoutRight">
                  <MicrosoftButton signIn={false} />
              </div>
          </div>
      );
  }
}

export default Header;