import countryCodeArray from './countryCodes.json'
export * from './requests';
export * from './graphRequests';
export * from './origoAdapter';


export function countryCodeRegexString(){
    let result = "^(";
    for (let i = 0; i<countryCodeArray.length-1; i++){
        result+="\\"+countryCodeArray[i]+"|";
    }
    result += "\\"+countryCodeArray[countryCodeArray.length-1] + ")[1-9]\\d{4,15}$";
    return result
}

export function formatDateString(inputDateString?: string): string {
    if (!inputDateString) return ""
    const date = new Date(inputDateString);
    const dateString = date.toLocaleString("sv-SE");
    return dateString === 'Invalid Date'? "" : dateString;
    const year = date.getFullYear().toString().slice(2);
    const month = (date.getMonth()+1).toString();
    const day = date.getDate().toString();
    const hours = date.getHours() > 9 ? date.getHours().toString() : "0"+date.getHours();
    const minutes = date.getMinutes() > 9 ? date.getMinutes().toString() : "0"+date.getMinutes();
    const seconds = date.getSeconds() > 9 ? date.getSeconds().toString() : "0"+date.getSeconds();

    return day+'/'+month+'-'+year+'\n'+hours+':'+minutes+':'+seconds;
}