import { countryCodeRegexString } from "../../util";
import { IOrigoUser, isIOrigoUser } from "../../types/types";
import { EMPLOYEE_ID_MAX_LENGTH, EMPLOYEE_ID_MIN_LENGTH, dataSubmitStringsKey, IOrigoDataUserInput } from "../DataSubmitForm";

export const helpers = {
   
    determineUserInputIdError: (value: string, currentError: dataSubmitStringsKey): dataSubmitStringsKey => {
    return helpers.containsNonNumbers(value)
        ?
        "wrongIdFormat"
        :
        value.length === EMPLOYEE_ID_MIN_LENGTH
        ?
        currentError === "tooManyChars" || currentError === "wrongIdFormat"
            ?
            ""
            :
            currentError
        :
        value.length > EMPLOYEE_ID_MAX_LENGTH + 6
            ?
            "tooManyChars"
            :
            "";
    },
    
    containsNonNumbers: (candidate: string): boolean => {
        return candidate.search(/[\D]/) >= 0;
    },

    containsNonWordChars: (candidate: string): boolean => {
        return candidate.search(/[^A-Za-zÅÄÖåäö -]/) >= 0;
    },

    isProperPin: (pin: string): boolean => {
        return pin === "" || pin.trim().match(/^\d*$/) !== null;
    },

    isProperMailAdress: (email: string): boolean => {
        return email.trim().match(/^[^@\s]*@[^@\s]+\.\w{2,63}$/) !== null
    },

    isProperPhoneNumber: (phoneNumber: string): boolean => {
        const matchNumber = helpers.proccessPhoneNumber(phoneNumber);
        // maxlängd svenskt nummer utan landskod 4 + 8 siffror, minlängd 2 +5 utökat för att fånga in holländska och brittiska
        const regexString = countryCodeRegexString();
        const regex = new RegExp(regexString);
        return regex.test(matchNumber);
        const match = matchNumber.match(/^\+\d{7,16}$/);
        return match !== null
    },

    proccessPhoneNumber: (phoneNumber: string): string => {
        const removedSpacesAndDashes = phoneNumber.replaceAll(/(\s|-)/g, "");
        // const replaceLeadingZeroWithPlus46 = removedSpacesAndDashes.replace(/^0/, "+46");
        return removedSpacesAndDashes;
    },

    isProperBirthDate: (birthdate: string, progression: "building" | "complete"): boolean => {
        if (progression === "building") {
            const m = birthdate.match(/((^$|^1(9\d?)?$|^20*$)|(^(1(?=9)|2(?=00))\d{2}(?!-)(\d(?=-)|\d$)-?(?=([01]|$))(1(?=[012])|0(?=\d))?(\d(?=-)|\d$)?-?(?=([0123]|$))([012]|3(?=[01]))?\d?$))/);
            return m !== null;
        } else {
            return birthdate.match(/^(1(?=9)|2(?=00))\d{3}-(1(?=[012])|0(?=\d))\d-([012]|3(?=[01]))\d$/) !== null;
        }
    },

    appendBirthDateDemarkers: (value: string, currentBdString: string): string => {
        const cbdLength = currentBdString.length;
        if (currentBdString[cbdLength - 1] === '-' && (value.length < cbdLength)) {
            return value.replace(/.$/, '')
        }
        return value.match(/(^\d{5}$|^\d{4}-\d{3}$)/) !== null ? value.replace(/(?=\d$)/, '-') : value
    }
}

