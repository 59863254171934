export function AlreadyActivatedPromptContent() {
    return (
        <div>
            <p>Oops! It appears the ID Number you've entered is linked to an account that's already active. Please double-check and ensure you've entered the correct ID Number.</p>
            <p>If you've misplaced your login details for this account and need them to be sent to you again, please click on the button below.</p>
            <p>In case you've forgotten your password, simply follow <a href="https://passwordreset.microsoftonline.com/">this link</a>, and follow the instructions.</p>
            <p>Should you need to alter your login information for this account, kindly reach out to our IT support team at itsupport@stena.com. They'll assist you with reactivating your account with updated login information.</p>
            <p>If you need your username resent to you, press the button below.</p>
        </div>
    )
}