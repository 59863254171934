import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Autocomplete, Button, TextField } from '@mui/material';
import './EditDialog.css';
import { IAzureUser as IAzureUser, IHrNoteRestObject, IOrigoUser as IExistingUser, IStenaUser, SmsType } from '../types/types';
import { requestAccessTokenBE } from '../requestAccessToken';
import { getFilteredMembers, getUserWithUpn } from '../util/graphRequests';
import { AccessLevel, UpdateType } from './LoggedIn';
import EditDialogHrComment from './promptContents/EditDialogHrComment';

interface IEditDialogProps {
  accessLevel: AccessLevel;
  isOpen: boolean;
  updateUser: (stenaUser: IStenaUser, updateType: UpdateType) => void;
  setIsOpen: any;
  context: any;
  user: IStenaUser;
  hrNote: IHrNoteRestObject;
  updateNote: (comment: IHrNoteRestObject) => void;
  sendSms: (comment: IHrNoteRestObject, type: SmsType) => void;
  sendCheckPhoneEmail: (comment: IHrNoteRestObject) => void;
}

interface IEditDialogState {
  givenName: string;
  lastName: string;
  employeeId: string;
  cardNumber: string;
  birthdate: string;
  phone: string;
  privateMail: string;
  filteredAzureUsers: IAzureUser[];
  selectedAzureUser: IAzureUser | undefined;
  existingUsers: IExistingUser[] | undefined;
}

class EditDialog extends React.Component<IEditDialogProps, IEditDialogState> {

  constructor(props: IEditDialogProps) {
    super(props);

    this.state = {
      givenName: props?.user?.firstName ?? "",
      lastName: props?.user?.lastName ?? "",
      employeeId: props?.user?.employeeId ?? "",
      cardNumber: props?.user?.cardNumber ?? "",
      birthdate: props?.user?.birthDate ?? "",
      phone: props?.user?.phoneNumber ?? "",
      privateMail: props?.user?.email ?? "",
      filteredAzureUsers: [],
      selectedAzureUser: undefined,
      existingUsers: undefined,
    };
  }

  public async componentDidUpdate(prevProps: any) {
    if (this.props.user !== prevProps.user) {
      this.setState({ givenName: this.props.user ? this.props.user.firstName : "" });
      this.setState({ lastName: this.props.user ? this.props.user.lastName : "" });
      this.setState({ employeeId: this.props.user ? this.props.user.employeeId : "" });
      this.setState({ cardNumber: this.props.user ? this.props.user.cardNumber : "" });
      this.setState({ birthdate: this.props.user ? this.props.user.birthDate : "" });
      this.setState({ phone: this.props.user ? this.props.user.phoneNumber : "" });
      this.setState({ privateMail: this.props.user ? this.props.user.email : "" });
    }
  }

  private transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  private updateSurname = (e: any) => {
    this.setState({ givenName: e.target.value });
  }

  private updateLastName = (e: any) => {
    this.setState({ lastName: e.target.value });
  }

  private updateId = (e: any) => {
    this.setState({ employeeId: e.target.value });
  }

  private updateBirthdate = (e: any) => {
    this.setState({ birthdate: e.target.value });
  }

  private updatePhone = (e: any) => {
    this.setState({ phone: e.target.value });
  }

  private updateMail = (e: any) => {
    this.setState({ privateMail: e.target.value });
  }

  private onFilterChanged = async (filterText: string | null, limitResults?: number) => {
    filterText !== null && filterText !== ""
      ? await getFilteredMembers(await requestAccessTokenBE(this.props.context), filterText).then((result) => {
        limitResults && result.payload.length > limitResults
          ? this.setState({ filteredAzureUsers: result.payload.slice(0, limitResults) })
          : this.setState({ filteredAzureUsers: result.payload })
      })
      : this.setState({ filteredAzureUsers: [] })
  }

  private saveChanges = async (updateType: UpdateType) => {
    // await updateStenaUser(await requestAccessTokenBE(this.props.context), this.props.user);
    const userToUpdate = { ...this.props.user }
    userToUpdate.firstName = this.state.givenName;
    userToUpdate.lastName = this.state.lastName;
    userToUpdate.birthDate = this.state.birthdate;
    userToUpdate.phoneNumber = this.state.phone;
    userToUpdate.email = this.state.privateMail;
    this.props.setIsOpen(false);
    this.props.updateUser(userToUpdate, updateType);
  }

  private updateWithAzureData = () => {
    const { selectedAzureUser } = this.state
    if (!selectedAzureUser) return;
    this.setState({
      givenName: selectedAzureUser.givenName,
      lastName: selectedAzureUser.surname,
      birthdate: selectedAzureUser.birthdate || ""
    })
  }


  private azureOptions = (users: IAzureUser[]): string[] => {
    return users.map((user) => {
      return user.displayName + " " + user.upn;
    })
  }

  render(): React.ReactNode {
    const { filteredAzureUsers, selectedAzureUser } = this.state;
    const { user } = this.props;
    return (
      <div className='editDialogContainer'>
        <Dialog
          open={this.props.isOpen && !!this.props.user}
          onClose={() => this.props.setIsOpen(false)}
          TransitionComponent={this.transition}
          maxWidth={'xl'}
        >
          <div className="editOuterDiv">
            <div className="editContentDiv">
              <div className="editdiv">
                { /*
                  <img className='editCardImage' src={user ? `data:image/jpeg;base64,${user.idCardImage}` : ""} />
                */}
                <div className="editTextFieldDiv">
                  <h1 className="editHeader">Edit User Info</h1>
                  <p className="infoText">This info needs to match an Azure user to be able to activate.</p>
                  <TextField size='medium' label="Given Name" onChange={this.updateSurname} fullWidth style={{ marginBottom: "20px" }} variant="outlined" value={this.state.givenName} />
                  <TextField size='medium' label="Last Name" onChange={this.updateLastName} style={{ marginBottom: "20px" }} fullWidth variant="outlined" value={this.state.lastName} />
                  <TextField size='medium' label="ID" onChange={this.updateId} fullWidth style={{ marginBottom: "20px" }} variant="outlined" value={this.state.employeeId} />
                  <TextField size='medium' label="Birthdate" onChange={this.updateBirthdate} fullWidth style={{ marginBottom: "20px" }} variant="outlined" value={this.state.birthdate} />
                  <TextField size='medium' label="Phone" onChange={this.updatePhone} fullWidth style={{ marginBottom: "20px" }} variant="outlined" value={this.state.phone} />
                  <TextField size='medium' label="Private Mail" onChange={this.updateMail} fullWidth style={{ marginBottom: "20px" }} variant="outlined" value={this.state.privateMail} />
                  {this.props.accessLevel === 'hasAdminAccess' && (<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <Button onClick={() => this.saveChanges("permanent")} style={{ width: "45%" }} size="large" variant="outlined">Save in database</Button>
                    <Button onClick={() => this.saveChanges("session")} style={{ width: "45%" }} size="large" variant="outlined">Save session only</Button>
                  </div>)}
                </div>
              </div>
              <div className="inputFields">
                {this.props.accessLevel === "hasAdminAccess" && <div className="searchUserdiv">
                  <h1 className="editHeader">Search for an Azure User</h1>
                  <p className="infoText">The user info to the left needs to match one of these users to be able to activate.</p>
                  <Autocomplete
                    onChange={async (event: any, newValue: string | null) => {
                      const upn = newValue?.substring(newValue?.lastIndexOf(" ") + 1);
                      if (upn !== undefined) {
                        await getUserWithUpn(await requestAccessTokenBE(this.props.context), upn).then(async (response) => {
                          var user = response.payload;

                          if (user !== null) {
                            this.setState({ selectedAzureUser: user });
                          }
                        })
                      }
                    }}
                    onInputChange={(event: any, newValue: string | null) => this.onFilterChanged(newValue)}
                    style={{ marginBottom: "33px" }}
                    disablePortal
                    options={this.azureOptions(filteredAzureUsers)}
                    fullWidth
                    renderInput={(params) => <TextField style={{ width: "100%", overflowX: "auto" }} {...params} label="Start writing a name..." />}
                  />
                  {selectedAzureUser &&
                    <div className="searchedUserInfoDiv">
                      <h1 className='searchedUserHeader'>Name: </h1>
                      <p className='searchedUserText'>{selectedAzureUser.displayName}</p>
                      <h1 className='searchedUserHeader'>Birthdate: </h1>
                      <p className='searchedUserText'>{selectedAzureUser.birthdate}</p>
                      <h1 className='searchedUserHeader'>ID: </h1>
                      <p className='searchedUserText'>{selectedAzureUser.id}</p>
                      <h1 className='searchedUserHeader'>UPN: </h1>
                      <p className='searchedUserText'>{selectedAzureUser.upn}</p>
                      <Button onClick={this.updateWithAzureData}>Set as this</Button>
                    </div>
                  }
                </div>}
                <div className="searchUserdiv">
                  <EditDialogHrComment
                    accessLevel={this.props.accessLevel}
                    hrNote={this.props.hrNote}
                    sendSms={this.props.sendSms}
                    updateNote={this.props.updateNote}
                    sendCheckPhoneEmail={this.props.sendCheckPhoneEmail}
                  />
                </div>
              </div>
            </div>
            <div className="editButtonDiv">
              <Button onClick={() => this.props.setIsOpen(false)} variant="outlined">Close</Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default EditDialog;