import { AlertColor } from "@mui/material";


export interface IOrigoUser {
    employeeId: string;
    cardNumber: string;
    firstName: string;
    lastName: string;
    company?: string;
    costCenter?: string;
    birthDate: string;
    pin?: string;
}

export const isIOrigoUser = (val: any): val is IOrigoUser => {
    if (!val) {
        return false;
    }
    if (typeof val !== "object") {
        return false;
    }
    return "employeeId" in val && "firstName" in val && "lastName" in val// && "cardNumber" in val && "birthDate" in val;
}

export interface IStenaUserInput {
    employeeId: string;
    cardNumber: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    birthDate: string;
    pinCodeUserInput?: string;
    activated: boolean;
}

export interface IStenaUserHrDTO {
    employeeId: string;
    hrApproved: boolean,
    hrDisapproved: boolean,
    hrComment: string,
}

export interface IStenaUser extends IStenaUserInput {
    company: string;
    costCenter: string;
    pinCodeOrigo: string;
    timestamp: string;
    timeActivated?: string;
    activatedUpn?: string;
    azureAccounts: IAzureUser[];
    hrApproved?: boolean;
    hrDisapproved?: boolean;
    hrComment?: string;
    RegionNotIncludedSmsSent?: string;
    origoFirstName?: string;
    origoLastName?: string;
}

export interface IClientFileObject {
    filename: string;
    fileContents: string;
}

export interface IDialogData {
    title: string;
    description: string | (() => JSX.Element);
    confirmButtonLabel?: string;
    dismissButtonLabel?: string;
}

export interface IBlobObject {
    filename: string;
    fileContents: string;
}

export enum Screensize {
    SMALL,
    MEDIUM,
    LARGE
}

export interface IAzureUser {
    displayName: string;
    givenName: string;
    surname: string;
    birthdate?: string;
    samAccountName: string;
    licenses: string[];
    stid: string;
    hasExchangeOnlineMailbox: boolean;
    isSeaLight: boolean;
    isStaffbase: boolean;
    isSeably: boolean;
    upn: string;
    id: string;
    oneData?: IOneData;
    hasSignedIn: boolean;
    lastSignIn: Date;
    accountStatus: string;
    accountEnabled: boolean;
}

export interface IOneData {
    manualCheckNeeded?: boolean;
    tsid: string;
    stid: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    seablyEmail: string;
    talentsoftEmail: string;
    isExternal: boolean;
    isActive: boolean;
    groupEndDate: string;
    phoneNumbers: string[];
    mailAddresses: string[];
}

export interface IActivatedUser {
    firstName: string;
    lastName: string;
    origoId: string;
    upn: string;
    mail: string;
    phone: string;
    timeActivated: string;
    chosenAzureAccount?: IAzureUser;
}

export function copyAzureInfo(original: AzureInfo): AzureInfo {
    const copy = new AzureInfo(original._azureAccounts);
    copy._chosenAzureUser = original._chosenAzureUser;
    copy._ignoreBirthdate = original._ignoreBirthdate;
    copy._passwordStates = original._passwordStates;
    return copy;
}

export class AzureInfo {
    _azureAccounts: IAzureUser[];
    _chosenAzureUser: IAzureUser;
    _ignoreBirthdate: boolean;
    _passwordStates: {
        [index: string]: "default" | "changePassword" | "keepPassword";
    }
    constructor(accounts: IAzureUser[]) {
        this._azureAccounts = accounts;
        this._chosenAzureUser = accounts.find(account => account.accountStatus === "Primary") ?? accounts[0];
        this._ignoreBirthdate = false;
        this._passwordStates = {}
        accounts.forEach(account => {
            this._passwordStates[account.upn] = "default"
        })
        this.setChosenAzureUser.bind(this)
    }

    get changePassword(): boolean {
        switch (this._passwordStates[this._chosenAzureUser.upn]) {
            case "changePassword": return true;
            case "keepPassword": return false;
            default: return !this._chosenAzureUser.lastSignIn;
        }
    }
    set changePassword(changeState: boolean) {
        this._passwordStates[this._chosenAzureUser.upn] = changeState ? "changePassword" : "keepPassword";
    }
    get chosenAzureUser(): IAzureUser {
        return this._chosenAzureUser
    }
    setChosenAzureUser(upn: string) {
        const chosenAzureUser = this._azureAccounts.find(account => account.upn === upn);
        if (chosenAzureUser === undefined) return;
        this._chosenAzureUser = chosenAzureUser;
    }
}

export type StatusTextColor = "red" | "black" | "orange"

export interface IErrorMessage {
    message: string;
    severity: StatusTextColor
}

export interface IErrorMessageMessageBar {
    message: string;
    severity: AlertColor;
}

export interface IActivationResult {
    activatedUser?: IStenaUser;
    messages: IErrorMessage[]
}
export interface IActivationServerResponse {
    activatedUser?: IStenaUser;
    issues: {
        message: string;
        type: number;
    }[]
}

export interface IStenaUserFetchFilter {
    offset: number,
    count: number,
    sortingField: string,
    sortDesc: boolean,
    search: string
}

export interface IStenaUserFetchFilterWithSearchTime extends IStenaUserFetchFilter {
    lastSearchTime: number;
}

export type HrNoteRestObjectStatus = "CouldNotUpdate" | "Success" | "JsonParsingError" | "UnvalidEntity" | "ActivationIssue" | "UnknownError";

export type HrNoteTags = {
    NotInStaffbase: "Not in Staffbase",
    NotInAzure: "Not in Azure",
    NoPin: "NoPin",
    Hidden: "Hidden",
    NoPhoneInOne: "No phone in One",
    CaseForWeekly: "Case for weekly",
    AlreadyInStaffbase: "Already in Staffbase",
    External: "External/Consultant",
    Resubmitted: "Form resubmitted";
    NotinOne: "Not in One";
    NoPhoneMatchEmailSent: "Email check phone sent";
}

export const hrNotesTagImpl: HrNoteTags = {
    NoPin: "NoPin",
    NotInAzure: "Not in Azure",
    NotInStaffbase: "Not in Staffbase",
    Hidden: "Hidden",
    NoPhoneInOne: "No phone in One",
    CaseForWeekly: "Case for weekly",
    AlreadyInStaffbase: "Already in Staffbase",
    External: "External/Consultant",
    Resubmitted: "Form resubmitted",
    NotinOne: "Not in One",
    NoPhoneMatchEmailSent: "Email check phone sent"
} as const;

export const hrNotesTagArray: HrNoteTagKey[] = Object.keys(hrNotesTagImpl) as HrNoteTagKey[];

export type HrNoteTagKey = keyof HrNoteTags;

export interface IHrNoteRestObject {
    employeeId: string;
    activationIssues: string;
    comments: string[];
    tags: HrNoteTagKey[];
    hrApprove: boolean;
    delaySmsSent: boolean;
    regionNotIncludedSmsSent: boolean;
    status: HrNoteRestObjectStatus;
    hrPreferredUpn: string;
}


export interface IHrDataMap {
    [index: string]: IHrNoteRestObject
}

export type SmsType = "ACCOUNT_ISSUE_DELAY" | "ACCOUNT_PROCESS_DELAY";

export function objectIsHrNoteRestObject(object: any): object is IHrNoteRestObject {
    return object.employeeId !== undefined && object.status !== undefined;
}

export function isKeyOf<T extends object>(obj: T, key: keyof any): key is keyof T {
    return key in obj;
}